import { toast } from "vue3-toastify";
/**
 * 
 * @returns {*}
 */
export const tenantClientFetch = async (request, options = {}, error_msg, success_msg) => {
    const tenant = useTenantStore();
    const cookie = useCookie('locale');
    let locale = cookie.value === undefined ? 'ru-RU' : toRaw(cookie.value);
    const defaults = {
        Accept: 'application/json',
        Authorization: `Bearer ${tenant.getToken}`,
        XLocaleGuard: locale,
        'X-Tenant': tenant.getTenantId
    };
    const headers = options && options.headers ? { ...options.headers, ...defaults } : defaults;
    if (options.headers) delete options.headers;

    const runtimeConfig = useRuntimeConfig()

    const result = await $fetch(request, { baseURL: `${runtimeConfig.public.baseCentralApiUrl}/`, headers, credentials: 'include', ...options });
    // work needed
    // if (!result.errors && success_msg) toast.success(success_msg); else if (result.errors && error_msg) toast.error(result.message)
   
    // if (result.errors) { console.error(`TenantClientFetchError: ${headers ? `${headers.method ? `[${headers.method}]` : '[GET]'}` : `[FAIL]`} ${tenant ? tenant.getTenantUrlPath : 'BASE_URL_NOT_FOUND'}/api/${request} has returned error: \n\n${result.message}`) }
    // if (!result.errors) return result
    return result
};